import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-left">
          <Link className="navbar-logo" to="/">
            <img
              className="img-logo"
              src="Logo.png"
              alt="logo"
              style={{ height: '50px' }}
            />
          </Link>
        </div>
        <ul className="navbar-links">
          <li>
            <Link className="navbar-link" to="/compress">Compress</Link>
          </li>

          <li>
            <Link className="navbar-link" to="/convert">Convert</Link>
          </li>
         
          <li>
            <Link className="navbar-link" to="/merge">Merge</Link>
          </li>
          <li>
            <Link className="navbar-link" to="/resize">Resize</Link>
          </li>
          {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
