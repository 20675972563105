import React from 'react';
import './Footer.css';
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

export const Footer = () => {
  return (
    <footer className="Footer">
      <div className="footer-container">
        {/* Brand and Copyright */}
        <div className="f-brand">
          &copy; 2024 Gloomweb Solutions. 
        </div>

        {/* Social Media Links */}
        <div className="f-socials">
          <a href="https://www.facebook.com/gloomwebsolutions" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FaFacebookSquare />
          </a>
          <a href="https://www.instagram.com/gloomweb_solutions/" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/company/gloomweb-solutions" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FaLinkedin />
          </a>
          <a href="https://twitter.com/gloomweb" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FaTwitter />
          </a>
          <a href="https://www.youtube.com/channel/UCL1EEzbSCTRzfBishqlz-IQ" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FaYoutube />
          </a>
        </div>

        
        <div className="f-links">
          <a href="/terms" className="footer-link">Terms of Use</a>
          <a href="/policy" className="footer-link">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
