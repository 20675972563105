import React from 'react';
import './Home.css';

import { Link } from 'react-router-dom';
import { FaCompressArrowsAlt } from 'react-icons/fa';
import { GiResize } from 'react-icons/gi';
import { BiMerge } from 'react-icons/bi';
import { SiConvertio } from 'react-icons/si';

const Home = () => {
  return (
    <div className="home">
      {/* Background */}
      <div className="container text-center">
        <div className="text-para">
          <h1 className="heading">
            All the PDF tools you need in one convenient location!
          </h1>
          <h2 className="sub-heading">
            Compress, Convert, Merge, and Resize files quickly and effortlessly.
            Best of all, they are 100% free and simple to use!
          </h2>
        </div>

        {/* Card Grid */}
        <div className="card-container">
          {/* Compress PDF */}
          <div className="card">
            <div
              className="card-body"
              style={{
                backgroundImage: 'url(/images/compress-bg.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <h5 className="card-title">
                <FaCompressArrowsAlt className="icon" />
              </h5>
              <h3 className="card-text">Compress PDF</h3>
              <p className="card-description">
                Compress your PDFs while maintaining quality and saving space.
              </p>
              <Link className="btn bg_clr" to="/compress">
                Select PDF
              </Link>
            </div>
          </div>

         
          {/* Convert to JPG */}
          <div className="card">
            <div
              className="card-body"
              style={{
                backgroundImage: 'url(/images/convert-bg.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <h5 className="card-title">
                <SiConvertio className="icon" />
              </h5>
              <h3 className="card-text">Convert to JPG</h3>
              <p className="card-description">
                Convert PDFs to high-quality images easily.
              </p>
              <Link className="btn bg_clr" to="/convert">
                Select PDF
              </Link>
            </div>
          </div>
  
          {/* Merge PDF */}
          <div className="card">
            <div
              className="card-body"
              style={{
                backgroundImage: 'url(/images/merge-bg.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <h5 className="card-title">
                <BiMerge className="icon" />
              </h5>
              <h3 className="card-text">Merge PDF</h3>
              <p className="card-description">
                Combine multiple PDFs into one seamless file.
              </p>
              <Link className="btn bg_clr" to="/merge">
                Select PDF
              </Link>
            </div>
          </div>


           {/* Resize Image */}
   <div className="card">
            <div
              className="card-body"
              style={{
                backgroundImage: 'url(/images/resize-bg.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <h5 className="card-title">
                <GiResize className="icon" />
              </h5>
              <h3 className="card-text">Resize Image</h3>
              <p className="card-description">
                Resize images by dimensions, percentage, or pixels.
              </p>
              <Link className="btn bg_clr" to="/resize">
                Select Image
              </Link>
            </div>
          </div>




        </div>
      </div>
    </div>
  );
};

export default Home;
