import React, { useState } from "react";
import "./Resize.css";
import { FaFileUpload } from "react-icons/fa";

const Resize = () => {
  const [file, setFile] = useState(null);
  const [scaleFactor, setScaleFactor] = useState(1);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const handleResize = async () => {
    if (!file) {
      alert("Please upload an image file.");
      return;
    }

    if (isNaN(scaleFactor) || scaleFactor <= 0) {
      alert("Please enter a valid scale factor greater than 0.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(
          (blob) => {
            const url = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = "resized_image.jpg";
            link.click();
            URL.revokeObjectURL(url);
          },
          file.type
        );
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="resize-container">
      <div className="resize-card">
        <h1 className="resize-title">Resize Image Tool</h1>
        <p className="resize-description">
          Upload an image and specify a scale factor to resize it. Download the
          resized image with just one click!
        </p>
        <div className="upload-section">
          <input
            type="file"
            id="uploadBtn"
            accept="image/*"
            hidden
            onChange={handleFileChange}
          />
          <label htmlFor="uploadBtn" className="upload-button">
            <FaFileUpload className="icon" /> Upload Image
          </label>
        </div>
        {file && <p className="file-name">Selected File: {file.name}</p>}
        <div className="scale-section">
          {/* <label htmlFor="scaleFactor">Scale Factor</label> */}
          <input
            type="number"
            id="scaleFactor"
            value={scaleFactor}
            onChange={(e) => setScaleFactor(parseFloat(e.target.value))}
            step="0.1"
            min="0.1"
            max="10"
            placeholder="Enter scale factor"
          />
        </div>
        <button
          className="resize-button"
          onClick={handleResize}
          disabled={!file}
        >
          Resize Image
        </button>
      </div>
    </div>
  );
};

export default Resize;
