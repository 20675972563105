import React, { useState } from 'react';
import "./Convert.css";
import { FaFileUpload, FaFilePdf } from "react-icons/fa";
import { PDFDocument } from 'pdf-lib';

const Convert = () => {
  const [files, setFiles] = useState([]);
  const [isConverting, setIsConverting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Handle file upload
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setErrorMessage(""); // Clear any previous errors
  };

  // Convert images to PDF
  const handleConvert = async () => {
    if (files.length === 0) {
      setErrorMessage("Please upload at least one JPG image.");
      return;
    }

    try {
      setIsConverting(true);
      const pdfDoc = await PDFDocument.create();

      for (const file of files) {
        const imageBytes = await file.arrayBuffer();
        const image = await pdfDoc.embedJpg(imageBytes);
        const { width, height } = image.scale(1);
        const page = pdfDoc.addPage([width, height]);
        page.drawImage(image, {
          x: 0,
          y: 0,
          width,
          height,
        });
      }

      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfBlob);

      // Create a link to download the PDF
      const link = document.createElement('a');
      link.href = url;
      link.download = 'converted_file.pdf';

      // Append to body, click to download, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the object URL
      URL.revokeObjectURL(url);

      setIsConverting(false);
    } catch (error) {
      console.error("Error converting images to PDF:", error);
      setErrorMessage("An error occurred while converting the images to PDF. Please try again.");
      setIsConverting(false);
    }
  };

  return (
    <div className="Convert">
      <div className="convert-container">
        <h1 className="convert-title">JPG to PDF Converter</h1>
        <p className="convert-description">
        Instantly convert JPG to PDF with customizable orientation and margins.
        </p>

        <div className="button-section">
          <div className="upload-section">
            <input
              type="file"
              id="uploadBtn"
              onChange={handleFileChange}
              accept=".jpg,.jpeg"
              multiple
            />
            <label htmlFor="uploadBtn" className="upload-label">
              <FaFileUpload className="icon-upload" /> Upload Images
            </label>
          </div>

          <button
            className="convert-btn"
            onClick={handleConvert}
            disabled={isConverting}
          >
            <FaFilePdf className="icon-convert" /> Convert to PDF
          </button>
        </div>

        {files.length > 0 && (
          <div className="file-list">
            <h3>Uploaded Files:</h3>
            <ul>
              {files.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}

        {isConverting && <p className="loading-text">Converting images to PDF, please wait...</p>}

        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Convert;
