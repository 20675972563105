import React from "react";
import "./Terms.css";

const Terms = () => {
  return (
    <div className="terms-wrapper">
      <div className="terms-content">
        <h1>Terms of Use</h1>
        <p>
          Welcome to Gloomweb Solutions. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions.
        </p>

        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing this website, you acknowledge that you have read, understood, and agree to be bound by these terms. If you do not agree, please do not use this website.
          </p>
        </section>

        <section>
          <h2>2. Use of the Website</h2>
          <p>
            You may use this website only for lawful purposes. You are prohibited from using the website in any way that may damage, disable, or impair it, or interfere with any other party's use of the website.
          </p>
        </section>

        <section>
          <h2>3. Intellectual Property</h2>
          <p>
            All content, trademarks, and other intellectual property displayed on this website are the property of Gloomweb Solutions or its licensors. Unauthorized use of any content is prohibited.
          </p>
        </section>

        <section>
          <h2>4. Limitation of Liability</h2>
          <p>
            Gloomweb Solutions is not responsible for any damages resulting from the use or inability to use the website or services provided.
          </p>
        </section>

        <section>
          <h2>5. Changes to Terms</h2>
          <p>
            We reserve the right to update these terms at any time. Please review this page regularly for updates.
          </p>
        </section>

        <section>
          <h2>6. Contact Information</h2>
          <p>
            For any questions about these terms, please contact us at{" "}
            <a href="mailto:support@gloomwebsolutions.com">
              support@gloomwebsolutions.com
            </a>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Terms;
