import React from 'react';
import Navbar from './component/Navbar/Navbar';
import Home from './component/home/Home';
import Footer from './component/Footer/Footer';
import Compress from './component/Compress/Compress';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Resize from './component/Resize/Resize';
import Merge from './component/Merge/Merge';
import Convert from './component/Convert/Convert';
import './App.css'; 
import Terms from './component/Terms/Terms';
import Policy from './component/Policy/Policy';



const App = () => {
  return (
    <div className="app-container">
      <Router>
        <Navbar />
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/compress" element={<Compress />} />
            <Route path="/resize" element={<Resize />} />
            <Route path="/merge" element={<Merge />} />
            <Route path="/convert" element={<Convert />} />
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/policy" element={<Policy />} />
           
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
