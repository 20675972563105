import React, { useState } from 'react';
import "./Compress.css";
import { FaFileUpload, FaDownload } from "react-icons/fa";
import imageCompression from 'browser-image-compression';
import { PDFDocument } from 'pdf-lib';

const Compress = () => {
  // const [file, setFile] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const handleFileChange = async (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFile(selectedFile);
  //   setErrorMessage(""); // Reset error message
  //   setCompressedFile(null); // Reset compressed file

  //   if (selectedFile) {
  //     if (selectedFile.type.includes('image')) {
  //       await compressImage(selectedFile);
  //     } else if (selectedFile.type.includes('pdf')) {
  //       await compressPDF(selectedFile);
  //     } else {
  //       setErrorMessage("Please upload a valid image (JPG/PNG) or PDF file.");
  //     }
  //   }
  // };
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setErrorMessage(""); // Reset error message
    setCompressedFile(null); // Reset compressed file
  
    if (selectedFile) {
      if (selectedFile.type.includes('image')) {
        await compressImage(selectedFile);
      } else if (selectedFile.type.includes('pdf')) {
        await compressPDF(selectedFile);
      } else {
        setErrorMessage("Please upload a valid image (JPG/PNG) or PDF file.");
      }
    }
  };
  

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1820,
      useWebWorker: true,
    };

    try {
      setIsLoading(true);
      const compressed = await imageCompression(imageFile, options);
      setCompressedFile(compressed);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("Error compressing image. Please try again.");
      setIsLoading(false);
    }
  };

  const compressPDF = async (pdfFile) => {
    try {
      setIsLoading(true);
      const existingPdfBytes = await pdfFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      const pdfBytes = await pdfDoc.save();
      const compressedPDF = new Blob([pdfBytes], { type: 'application/pdf' });
      setCompressedFile(compressedPDF);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("Error compressing PDF. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <div className="Compress">
      <div className="compress-container">
        <h1 className="compress-title">File Compression Tool</h1>
        <p className="compress-description">
        Compress size, retain high PDF quality.
        </p>

        <div className="upload-section">
          <input
            type="file"
            id="uploadBtn"
            onChange={handleFileChange}
            accept=".jpg,.jpeg,.png,.pdf"
          />
          <label htmlFor="uploadBtn" className="upload-label">
            <FaFileUpload className="icon-upload" /> Upload File
          </label>
        </div>

        {isLoading && <p className="loading-text">Compressing file, please wait...</p>}

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {compressedFile && (
          <div className="result-section">
            <h3>Compression Complete!</h3>
            <button
              className="download-btn"
              onClick={() => {
                const downloadUrl = URL.createObjectURL(compressedFile);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = "compressed_file";
                link.click();
              }}
            >
              <FaDownload className="icon-download" /> Download File
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Compress;
