import './Policy.css'; 
const Policy = () => {
  return (
    <div className="privacy-policy-container">
    <h1>Privacy Policy</h1>
    <p>Effective Date: [10/12/2024]</p>
    <p>
      At <strong>Gloomweb Solutions</strong>, we are committed to protecting your personal
      information and your right to privacy. This Privacy Policy outlines how we
      collect, use, and safeguard your information when you visit our website.
    </p>

    <h2>1. Information We Collect</h2>
    <p>
      We may collect personal information that you voluntarily provide to us
      when you register on the website, use our services, or interact with our features.
    </p>

    <h2>2. How We Use Your Information</h2>
    <p>
      We use the collected information to provide and improve our services,
      ensure site security, and communicate with you regarding updates or support.
    </p>

    <h2>3. Sharing Your Information</h2>
    <p>
      We do not share your personal information with third parties unless
      required to comply with legal obligations or provide specific services
      requested by you.
    </p>

    <h2>4. Your Privacy Rights</h2>
    <p>
      You have the right to access, update, or delete your personal information
      stored with us. For assistance, please contact us.
    </p>

    <h2>5. Contact Us</h2>
    <p>
      If you have questions or concerns about this Privacy Policy, you can reach us at:
      <br />
      <strong>Email:</strong> support@gloomweb.com
    </p>
  </div>
  );
};

export default Policy;  